* {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  margin: 0;
}

:root {
  --color-primary-light: #3f00ff;
  /*#6ddfff;*/
  --color-secondary-light: rgba(65, 102, 245, 0.5);
  --color-secondary-light-3: #4166f54d;
  --color-tertiary-light: #4166f5;
  --color-danger-light: #F8A522;
  --color-bg-light: #7fffd4;
  --color-title-light: black;
  --color-text-light: black;
  /* --color-bg-dark: #lightgray; */
  /* drk theme */
  --color-primary-dark: #61dafb;
  /* rgb(96, 217, 251) */
  --color-secondary-dark: rgba(109, 223, 255, 0.5);
  --color-secondary-dark-3: rgba(109, 223, 255, 0.3);
  --color-tertiary-dark: #ace5ee;
  --color-danger-dark: #F8A522;
  --color-bg-dark: #070d12;
  --color-title-dark: rgb(255, 255, 255);
  --color-text-dark: white;

  --text-title: 'JetBrains Mono', monospace;
  --button-border-shadow: drop-shadow(0 0 2px var(--color-primary-light));

  --min-md: 778px;

  --spinner-hex-time: 0.3s;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */